import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Slider from "./components/Content/Slider";
import FeaturesShort from "./components/Content/FeaturesShort";
import InstallationVideo from "./components/Content/InstallationVideo";
import Features from "./components/Content/Features";
import ITUCert from "./components/Content/ITUCert";
import Contact from "./components/Content/Contact";
import Montaza from "./components/Content/Montaza";
import Gallery from "./components/Content/Gallery/Gallery";

function App() {
  return (
    <div id="pocetna">
      <Header />
      <Slider />
      <FeaturesShort />
      <InstallationVideo />
      <Features />
      <ITUCert />
      <Montaza />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
