import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faColumns,
  faCalculator,
  faLink,
  faShieldAlt,
  faSmile,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./FeaturesShort.module.css";

function FeaturesShort() {
  function iconUp(e) {
    try {
      e.target.querySelector("SVG").style.translate = "0 -15px";
    } catch (ex) {}
  }

  function iconDown(e) {
    try {
      e.target.querySelector("SVG").style.translate = "0";
    } catch (ex) {}
  }

  return (
    <section>
    <div className={styles.fshort + " container"}>
      <h4 className="text-center mb-4">
        Nakon dugogodišnjeg ispitivanja, sa sigurnošću tvrdimo da je Winblock®
        čelični zaštitni sistem najefikasniji sistem za zaštitu od pada sa
        visine. Winblock® čelični sistem je najpovoljniji u odnosu na druga
        rešenja i nema alternativu u zaštitnim mrežama. Bezbednost koju nudi
        Winblock® čelični zaštitini sistem je na najvišem nivou na tržištu.
      </h4>
      <div className="row mt-4">
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faWrench} className="fa-3x mb-3" />
          <h4>Laka instalacija</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            Čelična mreža koja se lako montira u 3 koraka.
          </p>
        </div>
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faColumns} className="fa-3x mb-3" />
          <h4>Za sve građevinske materijale i svim dimenzijama</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            Kompatibilno sa aluminijumom, PVC-om, drvetom, betonom, metalnom
            galanterijom u svim dimenzijama i promerima
          </p>
        </div>
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faCalculator} className="fa-3x mb-3" />
          <h4>Ekonomično</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            Povoljnije u odnosu na slične proizvode na tržištu.
          </p>
        </div>
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faLink} className="fa-3x mb-3" />
          <h4>Izdržljivo</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            Naša zaštitna mreža je testirana na 250 kg opterećenja.
          </p>
        </div>
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faShieldAlt} className="fa-3x mb-3" />
          <h4>Pouzdano</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            Naša zaštitna mreža je sertifikovana na reprezentativnom tehničkom
            institutu.
          </p>
        </div>
        <div
          className="col-sm-6 col-md-4 p-3"
          onMouseEnter={iconUp}
          onMouseLeave={iconDown}
        >
          <FontAwesomeIcon icon={faSmile} className="fa-3x mb-3" />
          <h4>Bezbedno</h4>
          <hr className={styles.yellowLine} />
          <p className={styles.desc}>
            WinBlock® sigurnosni mrežni sistem je proizveden za bezbednost vaše
            dece u cilju prevencije pada sa visine.
          </p>
        </div>
      </div>
    </div>
    </section>
  );
}

export default FeaturesShort;
