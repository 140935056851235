
// import { useEffect, useState } from "react";
// import SingleGallery from "./SingleGallery";

function Gallery() {
  // let [gallery, setGallery] = useState(null); 
  // useEffect(() => {
  //   // console.log("Kreirana komponenta Gallery");
  //   async function getData() {
  //    await fetch('data/gallery-data.json')
  //     .then(response => {
  //       return response.json();
  //     }).then(data => {
  //       setGallery(data);
  //     }).catch((e) => {
  //       console.log(e.message);
  //     });
  //   }
  //   getData();
  // }, []);

  return (
    <section>
      <div className="container" id="galerija">
        <div className="row">
          <div className="text-center col-12 pb-4">
            <h1>Galerija</h1>
            <p>Uskoro</p>
          </div>
        </div>

          {/* {gallery && gallery.map( (singleGallery, galleryKey) => (
            <div className="row" key={galleryKey}>
              <div className="col-12 pt-4">
                <h2>{singleGallery.title}</h2>
                <p className="py-3">{singleGallery.desc}</p>
              </div>
              <div className="col-12 pb-3">
               <SingleGallery images={singleGallery.images} />
              </div>
            </div>
            ))}  */}
        </div>
    </section>
  );
}
export default Gallery;
