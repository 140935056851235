function ViberIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={props.size} width={props.size} className={props.className} fill="currentColor"
      style={{fontWeight: 'bold'}}
    >
      <path d="M7.066,22.256L5,21.223V18.74c-2.674-1.454-3-4.491-3-6.717V8.526C2,4.365,5.385,0.98,9.546,0.98h4.908 C18.615,0.98,22,4.365,22,8.526v3.497c0,4.22-3.584,7.922-7.671,7.922l-3.777,0.032L7.066,22.256z M9.546,2.98 C6.488,2.98,4,5.468,4,8.526v3.497c0,2.95,0.709,4.491,2.37,5.153L7,17.427v2.483l2.948-1.928l4.372-0.038 c3.03,0,5.68-2.768,5.68-5.922V8.526c0-3.058-2.488-5.546-5.546-5.546H9.546z" /><path d="M13.17,12.589c-0.208,0.23-0.521,0.352-0.826,0.29c-0.476-0.096-1.23-0.406-2.101-1.339 C9.524,10.772,9.12,9.954,8.973,9.385c-0.088-0.34,0.033-0.696,0.304-0.921l0.315-0.261c0.367-0.304,0.438-0.837,0.163-1.225 l-1.14-1.611C8.315,4.942,7.719,4.852,7.305,5.17L6.716,5.623C6.022,6.156,5.801,7.096,6.193,7.876 c0.738,1.467,1.975,3.729,3.018,4.691c1.282,1.184,3.296,2.586,4.99,3.276c0.741,0.302,1.589,0.091,2.109-0.516 c0.185-0.216,0.358-0.436,0.508-0.635c0.307-0.408,0.218-0.986-0.197-1.285l-1.871-1.347c-0.402-0.289-0.963-0.208-1.265,0.183 L13.17,12.589z" /><path d="M15,9.98h-1c0-0.645-0.355-1-1-1v-1C14.196,7.98,15,8.783,15,9.98z" /><path d="M17,9.98h-1c0-1.514-1.486-3-3-3v-1C15.056,5.98,17,7.924,17,9.98z" /><path d="M19,9.98h-1c0-2.365-2.053-5-5-5v-1C16.536,3.98,19,7.142,19,9.98z" />
    </svg>
  );

}
export default ViberIcon;