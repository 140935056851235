function InstallationVideo() {

  return (
    <section>
      <div className="container-fluid" id="video">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">Video za montažu</h1>
            <p className="text-center p-2">
              Pogledajte video za instalaciju koji smo pripremili da lako i ispravno instalirate WinBlock® sigurnosni mrežni sistem koji ste kupili.
            </p>
            <p className="text-center p-2">
              <strong>Pozivamo montere na saradnju - na teritoriji Beograda. Telefoni:&nbsp; 
                <a href="tel:+381600900606" aria-label="Telefon za poziv">060 090 06 06</a> i <a href="tel:+381652982991 " aria-label="Telefon za poziv">065 29 82 991</a>
              </strong>
            </p>
          </div>
          <div className="col-md-4 p-3">
            <div className="ratio ratio-16x9">
              <iframe width="560" height="314" src="https://www.youtube-nocookie.com/embed/H4H_OeCqzfM?rel=0" title="YouTube video player"
                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen loading="lazy"></iframe>
            </div>
            <p className="my-3 text-center">
              Video za unutrašnju montažu sa Z profilima
            </p>
          </div>

          <div className="col-md-4 p-3">
            <div className="ratio ratio-16x9">
              <iframe
                width="560"
                height="314"
                src="https://www.youtube-nocookie.com/embed/8s7DbWCWrWQ?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
            <p className="my-3 text-center">
              Video za unutrašnju montažu sa ravnim profilom
            </p>
          </div>

          <div className="col-md-4 p-3">
            <div className="ratio ratio-16x9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/gJrb_uOgZ-g?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
            <p className="my-3 text-center">
              Video za spoljašnju montažu sa ravnim profilima
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default InstallationVideo