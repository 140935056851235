import { useEffect } from "react";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import 'photoswipe/style.css';

function Montaza() {

  useEffect( () => {
    // console.log(props);
    // console.log("Kreirana komponenta Single Gallery");
    let lightbox = new PhotoSwipeLightbox({
      gallery: ".psg",
      children: "a",
      pswpModule: () => import("photoswipe"),
      showHideAnimationType: 'fade',
      bgOpacity: 0.75,
      wheelToZoom: true
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    }

  });
  return (
    <section>
    <div className="container" id="montaza">
      <div className="row">
          <div className="col-12">
            <h1 className="text-center">Montaža</h1>
          </div>
      </div>
      <div className="row psg">
          <div className="col-lg-4 text-center">
            <a href="img/montaza/montaza-winblock-korak-1.jpg" title="Korak 1" data-pswp-width="420"  data-pswp-height="660">
              <img src="img/montaza/montaza-winblock-korak-1.jpg" alt="Montaža sajle Winblock - korak 1." className="img-fluid" width="420" height="660" />
            </a>
          </div>
          <div className="col-lg-4 text-center">
            <a href="img/montaza/montaza-winblock-korak-2.jpg" title="Korak 2" data-pswp-width="420"  data-pswp-height="660">
              <img src="img/montaza/montaza-winblock-korak-2.jpg" alt="Montaža sajle Winblock - korak 2." className="img-fluid" width="420" height="660"/>
            </a>
          </div>
          <div className="col-lg-4 text-center">
            <a href="img/montaza/montaza-winblock-korak-3.jpg" title="Korak 3" data-pswp-width="420"  data-pswp-height="660">
              <img src="img/montaza/montaza-winblock-korak-3.jpg" alt="Montaža sajle Winblock - korak 3." className="img-fluid" width="420" height="660"/>
            </a>
          </div>
      </div>
    </div>
    </section>
  )
}
export default Montaza;