import { List } from "react-bootstrap-icons";
import {useEffect, useState} from 'react'
import TopNavBar  from "./TopNavBar";
function Header() {
  const [bck, setBck] = useState("");

  const handleScroll = () => {
    // console.log(window);
    if (window.scrollY > 10) {
      setBck('tinyHeader');
    } else {
      setBck ("")
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
   }, []);


  return (
    <div className="stay-top" >
      <TopNavBar />
      <nav className={bck + " navbar navbar-expand-lg"}>
        <div className="container-fluid d-flex justify-content-between">
          <a href="/" title="Početna" aria-label="Početna strana">
            <img src="img/winblock-main-logo-mobile.png" alt="Winblock logo" className="d-sm-block d-lg-none img-fluid" id="mainLogo" width="60" height="45" />
          </a>
          <span className="d-none d-sm-block d-lg-none">čelični sigurnosni mrežni sistem</span>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNav" aria-label="Glavni meni">
            <List />
          </button>
          <div className="collapse navbar-collapse" id="mainNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="#pocetna">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Početna</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#video">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Video</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#karakteristike">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Karakteristike</span>
                </a>
              </li>
              <li className="nav-item d-none d-lg-block">
                <a href="/">
                  <img src="img/winblock-main-logo.png" alt="Winblock logo" className="img-fluid" id="mainLogo" />
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#montaza">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Montaža</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#galerija">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Galerija</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#kontakt">
                  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Kontakt</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Header;
