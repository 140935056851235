import styles from "./Features.module.css";
function Features() {
  function iconLeft(e) {
    try {
      e.target.querySelector("IMG").style.translate = "-15px 0";
    } catch (ex) {}
  }

  function iconReturn(e) {
    try {
      e.target.querySelector("IMG").style.translate = "0";
    } catch (ex) {}
  }

  return (
    <section>
      <div className="container" id="karakteristike">
        <div className="row">
          <div className="col-12 text-center p-4">
            <h1>WinBlock® Karakteristike</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 p-4" onMouseEnter={iconLeft} onMouseLeave={iconReturn}>
            <div className="row">
              <div className="col-sm-2">
                <img src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-01.png`} alt="." className="img-fluid" loading="lazy" width="96" height="96"/>
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Odgovara svim prozorima i terasama</h2>
                <p className={styles.desc}>
                  Dizajnirano za sve tipove prozora različitih dimenzija, terase, balkne, stepeništa... <br />
                  Moguća montaža na PVC, beton, fasadu, gelendere, drvo, aluminujum, metal...
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 p-4" onMouseEnter={iconLeft} onMouseLeave={iconReturn}>
            <div className="row">
              <div className="col-sm-2">
                <img src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-08.png`} alt="." className="img-fluid" loading="lazy" width="96" height="96" />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Nosivost do 250 kg</h2>
                <p className={styles.desc}>
                  Sistem je testiran u laboratorijama univerziteta ITU (Istanbul Technical University) pod različitim opterećenjima i na različitim tačkama. Dokazano je da čelične sajle sistema
                  WinBlock® Safety System mogu da izdrže do 250 kg tereta (ako je sistem pravilno instaliran u skladu sa uputstvima proizvođača).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-02.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Ne utiče na otvaranje i zatvaranje prozora</h2>
                <p className={styles.desc}>
                  Ne utiče na funkciju otvaranja i zatvaranja prozora. Ostaje
                  pričvršćeno unutra na okvirima prozora ili sa spoljne strane
                  prozora
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6 p-4" onMouseEnter={iconLeft} onMouseLeave={iconReturn}>
            <div className="row">
              <div className="col-sm-2">
                <img src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-03.png`} alt="." className="img-fluid" loading="lazy" width="96" height="96" />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Izrada u željenim dimenzijama</h2>
                <p className={styles.desc}>
                  Veličina okna je 110x110mm, debljina sajle je 2,5mm, a čeličnog profila je 110mm.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-04.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>PVC izolacija</h2>
                <p className={styles.desc}>
                  Zahvaljujući PVC materijalu kojim je obložena čelična sajla,
                  ne postoji mogućnost povređivanja.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-09.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Laka i brza instalacija</h2>
                <p className={styles.desc}>
                  WinBlock® sigurnosni mrežni sistem se lako i brzo postavlja,
                  uklanja i ponovo postavlja na drugi otvor (selidba).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-07.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>
                  Kompatibilan sa zaštitnim mrežama protiv insekata i roletnama
                </h2>
                <p className={styles.desc}>
                  Ne utiče na funkcionalnost i nema dodira sa prethodno (ili
                  naknadno) postavljenom mrežom protiv insekata ili roletnom.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-10.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Diskretan dizajn</h2>
                <p className={styles.desc}>
                  WinBlock® je dizajniran tako da ne ostavlja osećaj "pogleda
                  iza rešetaka". Zahvaljujući konstrukciji od takne sajle
                  debljine od samo 2,5 mm, postignut je vizuelni efekat odsustva
                  mreže.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-06.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Bezbednost</h2>
                <p className={styles.desc}>
                  Zahvaljujući posebnoj tehnologiji izrade čelične sajle
                  obložene PVC materijalom, ne postoji mogućnost da deca preseku
                  ovakvu konstrukciju makazama ili nožem. Međutim, u slučaju
                  evakuacije ili druge hitne situacije, odrasli mogu lako da je
                  preseku kleštima ili namenskim alatom za rad sa čeličnim
                  sajlama.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 p-4"
            onMouseEnter={iconLeft}
            onMouseLeave={iconReturn}
          >
            <div className="row">
              <div className="col-sm-2">
                <img
                  src={`${process.env.PUBLIC_URL}/img/karakteristike/spec-12.png`}
                  alt="."
                  className="img-fluid"
                  loading="lazy"
                  width="96"
                  height="96"
                />
              </div>
              <div className="col-sm-10">
                <h2 className={styles.fh}>Višekratna upotreba</h2>
                <p className={styles.desc}>
                  WinBlock® sistem se jednostavno i bezbedno montira i demontira
                  i zbog toga se može koristiti više puta. Rupe za vijke se ne
                  nalaze na vidljivim površinama prozora tako da se ne vide
                  nakon ponovne ugradnje, ne narušavaju izgled i funkcionalnost
                  prozora i po potrebi im možete zatvoriti silikonom.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Features;
