function Slider() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="img/slider/_slide-1.jpg" className="d-block si"  alt="..." width="1920" height="auto"/>
          </div>
          <div className="carousel-item">
            <img src="img/slider/_slide-2.jpg" className="d-block si" alt="..." width="1920" height="auto" />
          </div>
          <div className="carousel-item">
            <img src="img/slider/_slide-3.jpg" className="d-block si" alt="..." width="1920" height="auto" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" aria-label="Prethodni slajd">
          <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
          <span className="visually-hidden" aria-hidden="true">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" aria-label="Sledeći slajd">
          <span className="carousel-control-next-icon" aria-hidden="true" ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Slider;
