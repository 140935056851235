import { Facebook, Instagram } from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import styles from "./Footer.module.css";

function Footer() {
  const [year, setYear] = useState("");
  useEffect(() => {
    const myDate = new Date();
    setYear(myDate.getFullYear());
  }, []);

  return (
    <footer className={styles.black}>
      <div className="container py-5">
        <div className="row">
          <div className="col-sm-6">
            <h1>WinBlock®</h1>
            <p>WinBlock® Srbija je zvanični distributer i partner WinBlock®</p>
          </div>
          <div className="col-sm-6 text-sm-end">
            <h2> Pratite nas</h2>
            <a href="https://www.facebook.com/zastitnamreza" rel="noreferrer" target="_blank" aria-label="Facebook strana Winblock Srbija">
              <Facebook size="40" className="me-3" />
            </a>
            <a href="https://www.instagram.com/winblocksrbija/" rel="noreferrer" target="_blank" aria-label="Instagram strana Winblock Srbija">
              <Instagram size="40" />
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <p>&copy; {year}. WinBlock® čelični sigurnosni mrežni sistem</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
