import styles from "./Contact.module.css";
import {useState } from 'react'
import { TelephoneFill, EnvelopeFill, Facebook, Instagram, Whatsapp, CheckCircle, ExclamationCircle } from 'react-bootstrap-icons';
import ViberIcon from "../UIElements/ViberIcon";

function Contact() {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [sendButtonDisable, setSendButtonDisable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  function updateName(e) {
    setName(e.target.value);
  }

  function updateEmail(e) {
    setEmail(e.target.value);
  }

  function updatePhone(e) {
    setPhone(e.target.value);
  }

  function updateMessage(e) {
    setMessage(e.target.value);
  }

  function sendData(e) {
    e.preventDefault();
    setShowSuccess(false);
    setShowError(false);
    let data = new FormData();
    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("message", message);
    let myData = {
      name: "", phone: "", email:"", message:""
    }
    myData.name = name;
    myData.phone = phone;
    myData.email = email;
    myData.message = message;
    setSendButtonDisable(true);
    // console.log(myData);
    fetch('api/sendContact.php', {
      method: 'post',
      body: JSON.stringify(myData),
      headers: {
        "Content-Type": "application/json; charset=UTF-8"
      }
    }).then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data === 'ok') {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
    })
  }

  return (
    <section>
    <div className={`${styles.contact} container contact`} id="kontakt">
      <div className="row py-4">
        <div className="col-12 text-center">
          <h1 className="pb-3">Kontakt</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 px-sm-5">
          <h2 className="mb-4">Kontakt podaci</h2>
          <p className="my-3">
              <TelephoneFill size='1.4rem' className="me-3" /> Telefoni: <strong><a href="tel:+381600900606">060 090 06 06</a>, <a className="ps-2" href="tel:+381652982991">065 29 82 991</a></strong>
          </p>
          <p className="my-3">
            <Whatsapp size='1.4rem' className="me-3" /> Whatsapp: <strong><a href="whatsapp://send/?phone=%2B381600900606&text&type=phone_number&app_absent=0">060 090 06 06</a></strong>
          </p>
          <p className="my-3">
            <ViberIcon size="1.6rem" className="me-3" /> Viber: <strong><a href="viber://chat?number=%2B381600900606">060 090 06 06</a></strong>
          </p>          
          <p>
            <EnvelopeFill size='1.4rem' className="me-3" /> Email: <strong><a href="mailto:kontakt@winblock.rs">kontakt@winblock.rs</a></strong>
          </p>
          <p>
            <Facebook size='1.4rem' className="me-3" /> Facebook: <strong><a target="_blank" rel="noreferrer" href="https://www.facebook.com/zastitnamreza">Zaštitna mreža</a></strong>
          </p>
          <p>
            <Instagram size='1.4rem' className="me-3" /> Instagram: <strong><a target="_blank" rel="noreferrer" href="https://www.instagram.com/winblocksrbija/">winblocksrbija</a></strong>
          </p>
        </div>
          <div className="col-md-6 px-sm-5">
          <h2 className="mb-4">Kontakt formular</h2>
          <form onSubmit={sendData}>
            <div className="row">
              <div className="col-6">
              <div className="input-group input-group-sm mb-3">
                <input type="text" className="form-control" value={name} placeholder="Ime" onChange={updateName} required/>
              </div>
              </div>
              <div className="col-6">
              <div className="input-group input-group-sm mb-3">
                <input type="text" className="form-control" placeholder="Telefon" onChange={updatePhone} required/>
              </div>
              </div>
            </div>
            <div className="input-group input-group-sm mb-3">
              <input type="text" className="form-control" placeholder="Email" onChange={updateEmail} />
            </div>
            <div className="input-group input-group-sm mb-3">
              <textarea className="form-control" name="" cols="30" rows="10" placeholder="Poruka" onChange={updateMessage} required></textarea>
            </div>
            <div className="">
              <input type="submit" value="Pošalji" className={styles.dugme} id="sendMessage" disabled={sendButtonDisable} />
              
              {/* Ako je response data ok */}
              { showSuccess && 
                <div>
                  <p className={`alert ` + styles.sendSuccess + ` alert-dismissible my-4`} role="alert">
                    <CheckCircle size="1.4rem" className={`me-2 ` + styles.sendSuccessCheck} /> Vaša poruka je uspešno prosleđena.
                    <button type="button" className="btn-close" onClick={() => setShowSuccess(false)} aria-label="Close"></button>
                  </p>
                </div>
              }
              {/* Ako je response data nije ok */}
              { showError && 
                <div>
                  <p className={`alert ` + styles.sendError + ` alert-dismissible my-4`} role="alert">
                    <ExclamationCircle size="1.4rem" className={`me-2 ` + styles.sendSuccessCheck} /> Došlo je do greške. Vaša poruka nije prosleđena. <br /> Molimo Vas da pokuašte ponovo kasnije.
                    <button type="button" className="btn-close" onClick={() => setShowError(false)} aria-label="Close"></button>
                  </p>
                </div>
              }
             
            </div>
          </form>
        </div>
      </div>
    </div>
    </section>
  );
}
export default Contact;
